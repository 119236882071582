<template>
  <Defaultlayout :userprofile="userprofile">
    <section class="order-successfully">
      <div class="container" v-if="loading==false">
        <div class="successfull-order-info text-center">
            <div class="successfull-icon mx-auto d-flex justify-content-center align-items-center">
              <span class="d-flex justify-content-center"><img src="../../assets/images/svgs/right-sign.svg" alt="right-sign"></span>
            </div>
            <h2>Order placed successfully</h2>
            <div class="d-flex justify-content-center">
              <h6 class="mb-0 mr-md-5 mr-3">Your order number is </h6>
              <p class="mb-0">{{order_details.order_no}}</p>
            </div>
        </div>
        <div class="successfull-order-detail text-center">
          <p class="mb-0">
            {{order_details.tracking_details.filter(x=>x.status == 1)[this.order_details.tracking_details.filter(x=>x.status == 1).length -1].description}}
          </p>
          <ul class="list-unstyled px-0 py-4 mb-0 d-flex justify-content-center">
            <li class="mr-md-5 mr-3 text-left">
              <h5 class="mb-0">ORDER TOTAL</h5>
              <span>Payment Method</span>
            </li>
            <li class="text-left">
              <h5 class="mb-0">
                <span v-html="priceFormatter(order_details.order_amount)"></span>
              </h5>
              <span v-if="order_details.payment_method_name !=  null">{{order_details.payment_method_name}}<span v-if="order_details.pay_thru_wallet==1">,</span></span>
              <span v-if="order_details.pay_thru_wallet==1">Wallet</span>
            </li>
          </ul>
          <div class="row justify-content-center">
            <a href="javascript:void(0)" @click="showOrderDetails(order_details.order_id)" class="btn mr-3 mr-md-5 order-btn view-order" @mouseleave="changeColorOnLeave" @mouseover="changeColorOnHover">View Order</a>
            <a v-if="(order_details.order_status==1 || order_details.order_status==2) && allow_cancellation==1" href="javascript:void(0)" :style="colorObj" class="btn cancel-button-style hoverEffect order-btn" @click="cancelOrder(order_details.order_id, order_details.order_no)">Cancel Order</a>
          </div>
        </div>
      </div>
      <OrderDetail :orderid="parseInt(order_id)"></OrderDetail>
      <CancelOrder></CancelOrder>
      <div class="container" v-if="loading==true">
        <p class="text-center">
          <img src="../../assets/images/loading.gif" />
        </p>
      </div>
    </section>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import OrderDetail from "../order/OrderDetail";
import CancelOrder from "../order/CancelOrder";
import { bus } from '../../main'
import helper from "../../store/helper";
export default {
  name: "OrderPlaced",
  components: {
    Defaultlayout,
    OrderDetail,
    CancelOrder,
  },
  mixins:[helper],
  data() {
    return {
      loading: true,
      userprofile: null,
      currency_symbol: "₹",
      order_id: 0,
      store_data: {
        store_id: "",
        user_id: "0",
        lat: "",
        long: "",
      },
      orderdetail: {
        order_id: '',
      },
      store_details: null,
      order_details: null,
      allow_cancellation: null,
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    localStorage.removeItem("cancelled-order-no");
    
    
    this.order_id = localStorage.getItem("orderplaced_id");
    if(this.order_id != null && parseInt(this.order_id) != 0){
      this.getOrderDetails(this.order_id);
    } else {
      window.location = "/";
    }
    this.originalColor();
  },
  updated(){
    window.$('<style>.successfull-order-detail .view-order:hover, .successfull-order-detail .view-order:focus { color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.successfull-order-detail .view-order:hover, .successfull-order-detail .view-order:focus { border-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
  },
  methods: {
    priceFormatter(value) {
      if(value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        return this.currency_symbol + price + "<span class='price-decimal'><small>." + decimal + "</small></span>";
      } else {
        return '0';
      }
    },
    getStoreDetails() {
      this.store_data.store_id = localStorage.getItem("order_storeid");
      this.store_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.store_data.lat = JSON.parse(localStorage.getItem("location_details")).lat;
      this.store_data.long = JSON.parse(localStorage.getItem("location_details")).lng;
      this.$store
        .dispatch("getstrepagedetail", this.store_data)
        .then(response => {
            var result = JSON.parse(JSON.stringify(response));
            if(result.status == 1){
              this.store_details = result.data;
              this.allow_cancellation = this.store_details.order_settings.allow_cancellation;
            }
        });
    },
    getOrderDetails(orderid) {
      this.loading = true;
      this.orderdetail.order_id = orderid;
      this.$store
        .dispatch("getcustomerorderdetail", this.orderdetail)
        .then(response => {
          this.order_details = response.data;
          this.loading = false;
          this.getStoreDetails();
        });
    },
    showOrderDetails(orderid) {
      this.order_id = orderid;
      this.orderdetail.order_id = orderid;
      this.$store
        .dispatch("getcustomerorderdetail", this.orderdetail)
        .then(response => {
          bus.$emit("ShowOrderDetails", JSON.parse(JSON.stringify(response)));
          window.$("#order-details-popup").modal("show");
        });
    },
    getPaymentMethod(payment_method_id){
      let payment_method = '';
      switch (payment_method_id) {
        case 0:
          payment_method = 'Add to Khata';
        break;
        case 1:
          payment_method = 'Cash on Delivery';
        break;
        case 2:
          payment_method = 'Touchless Digital Payment@Delivery';
        break;
        case 3:
          payment_method = 'Payment Thru UPI';
        break;
        case 4:
          payment_method = 'Online Payment';
        break;
      }
      return payment_method;
    },
    getDeliveryType(delivery_type_id){
      let delivery_type = '';
      switch (delivery_type_id) {
        case 1:
          delivery_type = 'delivery';
        break;
        case 2:
          delivery_type = 'pickup';
        break;
      }
      return delivery_type;
    },
    cancelOrder(order_id, order_no){
      bus.$emit("cancel_order_id", order_id);
      bus.$emit("cancel_order_no", order_no);
      window.$("#OrderCancelModal").modal("show");
    },
    changeColorOnHover(){
      window.$('.order-successfully .successfull-order-detail .view-order:hover').css("background-color",localStorage.getItem("button_hover_color"));
      window.$('.order-successfully .successfull-order-detail .view-order:hover').css("color",localStorage.getItem("css_button_customer_hover_font"));
    },
    changeColorOnLeave(){
      window.$('.order-successfully .successfull-order-detail .view-order').css("background-color","#fff");
      window.$('.order-successfully .successfull-order-detail .view-order').css("color",localStorage.getItem("button_default_color"));
    },
    
  }
};
</script>